import React from "react";
import "./App.css";
import Homepage from "./components/homepage/homepage";

function App() {
  return (
    <div>
      <Homepage />
    </div>
  );
}

export default App;
